
import { defineComponent, watch, useStore, reactive, ref, useContext, inject, onMounted, toRefs, nextTick, useRoute } from '@nuxtjs/composition-api'
import spanBadge from './spanBadge'
import goodsTableList from './goodsTableList'
import addCart from '@/components/spotMall/addCart.vue'
import quickEnquiry from '@/components/c2m/quickEnquiry.vue'
import { useLink } from '@/hooks/useLink'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { invoiceModeSecond } from '@/constant/goods'
import { formatNumber, mathAdd, mathDiv, mathMul } from '@/utils/tool'
import { debounce } from '@/utils'
import qualityAssure from '~/components/spotMall/qualityAssure.vue'
import { C2M_REPO_IDS, JIN_MAO_ID } from '@/constant'

export default defineComponent({
  components: {
    spanBadge,
    goodsTableList,
    addCart,
    qualityAssure,
    quickEnquiry
  },
  props: {
    condition: {
      type: Object,
      default: () => {}
    },
    isBatch: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const context = useContext()
    const store = useStore()
    const route = useRoute()
    const $message = inject('$message')
    const { replace, push } = useLink()
    const { userInfo, loginCorpId, isCartAuth, isJinMaoCompany } = useLoginInfo()
    const { judgeIsLogin, judgeIsCompany, judgeIsAuth, showErrorTip } = useMsgDialog()
    const list = ref([])
    const state = reactive({
      tableLoading: true,
      warranty: '',
      goodsInfo: {},
      parentRow: {},
      visiblePopover: false,
      repositoryAddress: '',
      tempRef: '',
      activeId: ''
    })
    const getInitBatch = () => {
      return {
        checkedList: [],
        hasChoseNum: 0,
        totalWeight: 0,
        totalMoney: 0,
        checkAll: false,
        isIndeterminate: false,
        checkAllDisabled: false
      }
    }
    const checkState = reactive(getInitBatch())
    const tablePage = reactive({
      total: 0
    })
    const query = reactive({
      page: 1,
      limit: 50,
      orderFields: [
        { field: '货主类型', order: '2' },
        { field: '入库日期', order: '1' },
        { field: '材质', order: '1' }
      ]
    })
    const tableEmpty = ref(false)
    let source = context.$axios.CancelToken.source()
    const getGoodsLists = () => {
      debounce(async () => {
        if (state.tableLoading == true) {
          source.cancel()
        }
        state.tableLoading = true
        source = context.$axios.CancelToken.source()
        emit('condition', { ...query })
        const { statusCode, data, message } = await context.$api.goods.getGoodsLists({ ...query }, source.token)
        state.tableLoading = false
        if (statusCode == 200) {
          const { rows, total } = data
          rows.forEach((row) => {
            row.checked = false
            row.isIndeterminate = false
            row.disabled = false
          })
          list.value = rows ?? []
          tablePage.total = total
          state.tableEmpty = !rows || rows.length == 0
        } else if (statusCode != 1500) {
          $message.error(message)
        }
      }, 500)
    }

    const getInitParams = () => {
      return {
        variety: '',
        categoryId: '',
        material: '',
        quality: '',
        deliveryPlace: '',
        repositoryId: '',
        origin: '',
        spec: '',
        widthStart: '',
        widthEnd: '',
        lengthStart: '',
        lengthEnd: '',
        thicknessStart: '',
        thicknessEnd: '',
        measure: '',
        qualityInfo: '',
        sizeInfo: '',
        specialPrice: '',
        invoiceList: [],
        type: '',
        sortName: '',
        sortType: '',
        repositoryName: '',
        materialLike: '',
        designation: '',
        sv: '',
        sk: '',
        sr: '',
        keyWord: '',
        matchList: '',
        listedPriceStart: '',
        listedPriceEnd: '',
        warehouseDateEnd: '',
        warehouseDateStart: '',
        page: 1,
        limit: 50
      }
    }
    const getListAsync = async () => {
      await handleQuery()
      getGoodsLists()
    }

    const handleQuery = () => {
      if (query.sortName) {
        query.orderFields = [
          {
            field: query.sortName,
            // 1升序2降序
            order: query.sortType
          }
        ]
        if (query.sortName == '规格') {
          query.orderFields = [
            { field: '厚', order: query.sortType },
            { field: '宽', order: query.sortType },
            { field: '长', order: query.sortType }
          ]
        }
      } else {
        query.orderFields = [
          { field: '货主类型', order: '2' },
          { field: '入库日期', order: '1' },
          { field: '材质', order: '1' }
        ]
      }
      if (query?.invoiceList?.length) {
        query.invoiceList = query.invoiceList?.split(',').map((_) => _ + '类')
      } else {
        query.invoiceList = []
      }
      // delete query.sortName
      // delete query.sortType
      delete query.sv
      delete query.sk
      delete query.sr
      // delete query.keyWord
      delete query.matchList
      delete query.invoiceList
    }

    const handleWantBuy = () => {
      if (!userInfo.value) {
        push('/login', { redirect: '/wantBuy' })
        return
      }
      push('/wantBuy')
    }
    const tableRef = ref(null)
    const handleToggleRow = async (row) => {
      if (!row.isExpand && !row.goodsList && !row.loading) {
        const { designation = '', warehouseDateStart = '', warehouseDateEnd = '' } = query
        const { goodsList, salesPrice, ...params } = row
        row.loading = true
        const { data, statusCode } = await context.$api.goods.getSearchDetails({
          designation,
          warehouseDateStart,
          warehouseDateEnd,
          listedPrice: salesPrice,
          ...params
        })
        row.loading = false
        if (statusCode == 200) {
          data.forEach((res) => {
            res.choseNum = 1
            res.checked = false
            res.loading = false
          })
          row.goodsList = data
        }
      }
      row.isExpand = !row.isExpand
      tableRef.value.toggleRowExpansion(row, row.isExpand)
      setTimeout(() => {
        if (floatbarRef.value) {
          nextTick(() => {
            floatbarRef.value && floatbarRef.value.handleScroll()
          })
        }
      }, 0)
    }

    const addCartRef = ref(null)
    const addCartRow = ref({})
    const handleAddCart = (row, parentRow) => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      if (!loginCorpId.value) {
        judgeIsCompany()
        return
      }
      if (!isCartAuth.value) {
        judgeIsAuth()
        return
      }
      state.parentRow = parentRow
      if (row.quantity > 1) {
        addCartRow.value = { ...row }
        addCartRef.value.dialogVisible = true
      } else if (row.quantity == 1) {
        sureAddCart(row)
      }
    }

    const sureAddCart = async (row) => {
      row.loading = true
      const { statusCode, data, message } = await context.$api.orderCart.addCart({
        resourceId: row.resourceId,
        quantity: row.quantity
      })
      row.loading = false
      if (statusCode == 200) {
        $message.success('加入购物车成功')
        store.dispatch('spotMall/GET_CARTSNUMBER', context)
        row.cartQuantity = 1
      } else if (statusCode == 30010 || statusCode == 30020) {
        showErrorTip(message)
      } else {
        $message.error(message)
      }
    }

    const qualityAssureRef = ref(null)
    const handleQuality = (data) => {
      state.warranty = data
      qualityAssureRef.value.dialogVisible = true
    }
    watch(
      () => userInfo.value,
      () => {
        getListAsync()
      }
    )
    watch(
      () => props.condition,
      () => {
        Object.assign(query, getInitParams(), { ...props.condition })
        getListAsync()
      },
      {
        deep: true
      }
    )

    const transParams = (object) => {
      const params = {}
      for (const i in object) {
        if (object[i]) {
          params[i] = object[i]
        }
      }
      return params
    }

    const handleSizeChange = (val) => {
      query.limit = val
      query.page = 1
      const params = transParams(query)
      Object.assign(params, { limit: val, page: 1 })
      delete params.orderFields
      replace('/shop', params)
      emit('change', params)
    }
    const handleCurrentChange = (val) => {
      query.page = val
      const params = transParams(query)
      Object.assign(params, { page: val })
      delete params.orderFields
      replace('/shop', params)
      emit('change', params)
    }

    const getHasChosedNum = () => {
      return checkState.checkedList.reduce((acc, cur) => (acc += cur.choseNum || 0), 0)
    }
    const getAllWeight = () => {
      const allWeight = checkState.checkedList.reduce((acc, cur) => {
        const choseWeight = MathRound(mathMul(mathDiv(cur.weight, cur.quantity), cur.choseNum || 0), 3)
        return MathRound(mathAdd(acc, choseWeight), 3)
      }, 0)
      return allWeight
    }
    const getAllMoney = () => {
      const allMoney = checkState.checkedList.reduce((acc, cur) => {
        const choseWeight = MathRound(mathMul(mathDiv(cur.weight, cur.quantity), cur.choseNum || 0), 3)
        const _listPrice = MathRound(mathMul(choseWeight, cur.listedPrice ?? 0))
        return MathRound(mathAdd(acc, _listPrice), 2)
      }, 0)
      return allMoney
    }

    const MathRound = (number, pow = 2) => {
      return Math.round(number * Math.pow(10, pow)) / Math.pow(10, pow)
    }

    const getCheckList = () => {
      checkState.checkedList = list.value.reduce((acc, cur) => {
        if (cur.goodsList) {
          acc = [...acc, ...cur.goodsList.filter((res) => res.checked)]
        }
        return acc
      }, [])
    }

    const handleChange = () => {
      checkState.hasChoseNum = getHasChosedNum()
      checkState.totalWeight = getAllWeight()
      checkState.totalMoney = getAllMoney()
    }
    watch(
      () => checkState.checkedList,
      (value) => {
        getAllCheckState()
        handleChange()
      }
    )

    const getBatchParams = () => {
      const params = []
      checkState.checkedList.forEach((list) => {
        params.push({
          quantity: list.choseNum,
          resourceId: list.resourceId
        })
      })
      return params
    }
    const tipMessage = ref('')
    const confirmRef = ref(null)
    const handleCart = async () => {
      if (!checkState.checkedList.length) {
        tipMessage.value = '请选择要加入购物车的商品'
        confirmRef.value.dialogVisible = true
        return
      }
      const { statusCode, data, message } = await context.$api.orderCart.addBatchCart(getBatchParams())
      if (statusCode == 200) {
        $message.success('加入购物车成功')
        store.dispatch('spotMall/GET_CARTSNUMBER', context)
        emit('batchShop', false)
      } else if (statusCode == 30010 || statusCode == 30020) {
        showErrorTip(message)
      } else {
        $message.error(message)
      }
    }
    const tipConfirm = () => {
      confirmRef.value.dialogVisible = false
    }

    const getAllCheckState = () => {
      const selectedList = list.value.filter((_) => _.checked)
      checkState.checkAll = list.value.length === selectedList.length
      checkState.isIndeterminate = !!(selectedList.length && selectedList.length < list.value.length)
    }

    const getSearchDetailsListAsync = async () => {
      state.tableLoading = true
      const { designation = '' } = query
      list.value.forEach((_) => {
        _.designation = designation
        _.listedPrice = _.salesPrice
      })
      const { statusCode, data } = await context.$api.goods.getSearchDetailsList(list.value)
      state.tableLoading = false
      if (statusCode == 200) {
        getMergeDetailList(data)
      }
      setTimeout(() => {
        if (floatbarRef.value) {
          nextTick(() => {
            floatbarRef.value && floatbarRef.value.handleScroll()
          })
        }
      }, 0)
    }

    const getMergeDetailList = (details) => {
      list.value.forEach((row, index) => {
        details[index]?.listedDetailSimpleDtoList?.forEach((res) => {
          res.checked = false
          res.choseNum = 1
        })
        row.goodsList = details[index].listedDetailSimpleDtoList
        const goodsList = getFilterGoodsList(row.goodsList ?? [])
        row.disabled = goodsList.length == 0
        row.isExpand = false
      })
      const unableList = list.value.filter((res) => !res.disabled)
      checkState.checkAllDisabled = unableList == 0
    }

    const getGoodsAddState = (goodsInfo) => {
      return goodsInfo.corpId != loginCorpId.value && (goodsInfo.invoiceMode != invoiceModeSecond || !isJinMaoCompany.value)
    }
    const getFilterGoodsList = (goodsList) => {
      return goodsList?.filter((res) => getGoodsAddState(res))
    }

    const handleCheckAllChange = (val) => {
      list.value.forEach((row) => {
        if (!row.disabled) {
          row.checked = val
          row.isIndeterminate = false
          row.goodsList?.forEach((res) => {
            if (getGoodsAddState(res)) {
              res.checked = val
            }
          })
        }
      })
      getCheckList()
    }

    const handleCheckbox = (row) => {
      row.checked = !row.checked
      row.isIndeterminate = false
      row.goodsList?.forEach((res) => {
        if (getGoodsAddState(res)) {
          res.checked = row.checked
        }
      })
      getCheckList()
    }

    const handleCheckboxList = (row) => {
      const goodsList = getFilterGoodsList(row.goodsList ?? [])
      const selectedList = goodsList.filter((_) => _.checked)
      row.checked = goodsList.length === selectedList.length
      row.isIndeterminate = !!(selectedList.length && selectedList.length < goodsList.length)
      getCheckList()
    }

    const clearCheckState = () => {
      Object.assign(checkState, getInitBatch())
    }
    const handleBatchCancel = () => {
      emit('batchShop', false)
    }
    const floatbarRef = ref(null)

    const handleSuccessAdd = (data, row) => {
      state.parentRow.goodsList.find((_) => _.id == row.id).cartQuantity += data.quantity || 0
    }

    const quickEnquiryRef = ref(null)
    const handlePublish = (row) => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      quickEnquiryRef.value.openDialog(
        {
          ...row
        },
        true
      )
    }
    const refMap = ref([])
    const popoverRef = ref(null)
    const handleEnter = (ref, item) => {
      state.visiblePopover = false
      state.repositoryAddress = item.repositoryAddress
      state.tempRef = ref
      nextTick(() => {
        state.visiblePopover = true
        nextTick(() => {
          popoverRef.value.doShow()
        })
      })
    }

    const getC2mIconState = (row) => {
      return C2M_REPO_IDS.includes(row.repositoryId) && row.corpId == JIN_MAO_ID
    }

    onMounted(() => {
      state.tableLoading = true
      Object.assign(query, getInitParams(), { ...route.value.query })
      getListAsync()
    })
    return {
      list,
      tableEmpty,
      handleWantBuy,
      formatNumber,
      userInfo,
      tableRef,
      handleToggleRow,
      handleAddCart,
      addCartRow,
      addCartRef,
      qualityAssureRef,
      handleQuality,
      ...toRefs(state),
      handleSizeChange,
      handleCurrentChange,
      ...toRefs(tablePage),
      handleCart,
      handleCheckAllChange,
      judgeIsLogin,
      ...toRefs(checkState),
      getInitBatch,
      getGoodsLists,
      clearCheckState,
      tipMessage,
      confirmRef,
      tipConfirm,
      handleBatchCancel,
      query,
      getListAsync,
      floatbarRef,
      handleCheckbox,
      handleCheckboxList,
      loginCorpId,
      getCheckList,
      handleSuccessAdd,
      getSearchDetailsListAsync,
      getC2mIconState,
      handlePublish,
      quickEnquiryRef,
      refMap,
      handleEnter,
      popoverRef
    }
  }
})
