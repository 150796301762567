
import {
  defineComponent,
  onMounted,
  ref,
  useContext,
  useStore,
  reactive,
  computed,
  watch,
  useRoute,
  inject,
  toRefs,
  useMeta
} from '@nuxtjs/composition-api'
import imageSwiper from '@/components/spotMall/imageSwiper.vue'
import goodsDetail from '@/components/spotMall/goodsDetail.vue'
import tipDialog from '@/components/spotMall/tipDialog.vue'
import quickEnquiry from '@/components/c2m/quickEnquiry.vue'
import warrantyTag from '@/components/spotMall/warrantyTag.vue'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useLink } from '@/hooks/useLink'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { integerReg } from '@/utils/validate'
import { invoiceModeSecond } from '@/constant/goods'
import { mathDiv, mathMul, formatNumber } from '@/utils/tool'
import { JIN_MAO, C2M_REPO_IDS, JIN_MAO_ID } from '@/constant'

export default defineComponent({
  components: {
    imageSwiper,
    goodsDetail,
    tipDialog,
    quickEnquiry,
    warrantyTag
  },
  layout: 'home',
  setup() {
    const context = useContext()
    const store = useStore()
    const route = useRoute()
    const $message = inject('$message')
    const { userInfo, loginCorpId, isCartAuth, isJinMaoCompany } = useLoginInfo()
    const { judgeIsLogin, judgeIsCompany, judgeIsAuth, showErrorTip } = useMsgDialog()
    const penaltyMoney = computed(() => store.getters.transactionParams?.entity?.penaltyMoney)
    const choseNum = ref(1)
    const state = reactive({
      isSkeleton: true,
      tempChoseNum: 1
    })
    const resourceId = ref('')
    const choseWeight = computed(() => {
      return MathRound(mathMul(mathDiv(goodsInfo.weight, goodsInfo.quantity), choseNum.value), 3)
    })
    const choseMoney = computed(() => MathRound(mathMul(choseWeight.value, goodsInfo.listedPrice), 2))
    const allWeight = computed(() => MathRound(goodsInfo.weight, 3))
    let goodsInfo = reactive({
      weight: '',
      quantity: 1,
      categoryName: '',
      displayPrice: '',
      goodsUrl: '',
      listedPrice: '',
      listTime: '',
      material: '',
      measure: '',
      origin: '',
      outboundCost: '',
      qualityInfo: '',
      repositoryName: '',
      resourceId: '',
      sizeInfo: '',
      spec: '',
      priceUpdateTime: '',
      repositoryCity: '',
      repositoryAddress: '',
      invoiceMode: '',
      warehouseDate: '',
      warranty: '',
      remark: ''
    })
    const goodsTitle = computed(() => {
      return `${goodsInfo.categoryName} ${goodsInfo.material} ${goodsInfo.spec ? goodsInfo.spec : ''} ${goodsInfo.origin}`
    })
    useMeta(() => ({
      title: goodsTitle.value || '详情'
    }))
    const invoiceModeTypes = ref({
      '平台开票(第二方)': JIN_MAO,
      '商家开票(第三方)': ''
    })
    const invoiceModeName = computed(() => invoiceModeTypes.value[goodsInfo.invoiceMode])
    const tipRef = ref(null)
    const getGoodsInfo = async () => {
      const { statusCode, data } = await context.$api.goods.getGoodsInfo({ resourceId: resourceId.value })
      if (statusCode == 200) {
        if (data) {
          state.isSkeleton = false
          goodsInfo = Object.assign(goodsInfo, data)
          if (goodsInfo.invoiceMode == '商家开票(第三方)') {
            invoiceModeTypes.value[goodsInfo.invoiceMode] = goodsInfo.corpName
          }
        } else {
          tipRef.value.dialogVisible = true
        }
      }
    }

    const MathRound = (number, pow = 2) => {
      return Math.round(number * Math.pow(10, pow)) / Math.pow(10, pow)
    }

    onMounted(() => {
      resourceId.value = route.value.query.id
      getGoodsInfo()
    })

    const qualityAssure = ref(null)
    const warrantyUrl = ref('')
    const { push } = useLink()

    const mainImg = ref('')
    const swiperImgs = computed(() => {
      if (goodsInfo.goodsUrl) {
        const urlArr = goodsInfo.goodsUrl.split(',')
        return urlArr
      } else {
        return []
      }
    })
    watch(
      () => swiperImgs.value,
      () => {
        mainImg.value = swiperImgs.value.length ? swiperImgs.value[0] : ''
      }
    )

    const changeInputNumber = (e) => {
      if (e && integerReg.test(e)) {
        state.tempChoseNum = choseNum.value
      }
    }
    const blurNumber = (e) => {
      if (!e.target.value || !integerReg.test(e.target.value)) {
        choseNum.value = state.tempChoseNum
      }
    }
    const addCart = async () => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      if (!loginCorpId.value) {
        judgeIsCompany()
        return
      }
      if (!isCartAuth.value) {
        judgeIsAuth()
        return
      }
      const { statusCode, data, message } = await context.$api.orderCart.addCart({ resourceId: goodsInfo.resourceId, quantity: choseNum.value })
      if (statusCode == 200) {
        $message.success('加入购物车成功')
        store.dispatch('spotMall/GET_CARTSNUMBER', context)
      } else if (statusCode == 30010 || statusCode == 30020) {
        showErrorTip(message)
      } else {
        $message.error(message)
      }
    }
    const sureBuy = async () => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      if (!loginCorpId.value) {
        judgeIsCompany()
        return
      }
      if (!isCartAuth.value) {
        judgeIsAuth()
        return
      }
      const { data, statusCode, message } = await context.$api.orderCart.validateBuyNow({
        type: 1,
        quantity: choseNum.value,
        resourceIds: [goodsInfo.resourceId]
      })
      if (statusCode == 200) {
        push('/shop/carts', { type: 1, quantity: choseNum.value, resourceIds: goodsInfo.resourceId, redirect: route.value.fullPath })
      } else if (statusCode == 30010 || statusCode == 30020) {
        showErrorTip(message)
      } else if (statusCode == 50030) {
        showErrorTip(message, 9)
      } else {
        $message.error(message)
      }
    }
    watch(
      () => userInfo.value,
      () => {
        getGoodsInfo()
      }
    )
    const getAfterSaleInfo = (detail) => {
      const qualityArr = ['正品', '分割正品', '船板', '分割船板']
      if (qualityArr.includes(detail.qualityInfo) && detail.specialPrice == '0') {
        return '受理质量异议'
      } else {
        return '不受理质量异议'
      }
    }

    const quickEnquiryRef = ref(null)
    const handleLinkToC2m = () => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      quickEnquiryRef.value.openDialog(
        {
          ...goodsInfo
        },
        true
      )
    }

    const goodsDetailRef = ref(null)
    const handleQuality = (url) => {
      goodsDetailRef.value.openQualityDialog(url)
    }

    const getC2mIconState = (row) => {
      return C2M_REPO_IDS.includes(row.repositoryId) && row.corpId == JIN_MAO_ID
    }

    const addCartDisabled = computed(() => {
      return loginCorpId.value == goodsInfo.corpId || (goodsInfo.invoiceMode == invoiceModeSecond && isJinMaoCompany.value)
    })

    return {
      ...toRefs(state),
      choseNum,
      userInfo,
      goodsInfo,
      goodsTitle,
      choseWeight,
      choseMoney,
      invoiceModeTypes,
      invoiceModeName,
      judgeIsLogin,
      warrantyUrl,
      qualityAssure,
      mainImg,
      swiperImgs,
      push,
      blurNumber,
      changeInputNumber,
      allWeight,
      addCart,
      sureBuy,
      loginCorpId,
      isJinMaoCompany,
      penaltyMoney,
      tipRef,
      getAfterSaleInfo,
      formatNumber,
      getC2mIconState,
      quickEnquiryRef,
      handleLinkToC2m,
      handleQuality,
      goodsDetailRef,
      addCartDisabled
    }
  },
  head: {
    title: '商品详情-金陵钢宝网'
  }
})
